//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from 'firebase';
import 'firebase/firestore';
import { mapActions, mapGetters } from 'vuex';
import { i18n } from '@/i18n';

export default {
  data() {
    return {
      fileName: '',
      loading: true,
      language: '',
      cities: [],
      regions: [],
      cityDialog: false,
      cityEn: '',
      cityAr: '',
      columns: [
        {
          name: 'ID',
          align: 'center',
          label: 'ID',
          field: 'id',
        },
        // { name: 'Region', align: 'center', label: 'Region', field: 'name',},
        {
          name: 'City',
          align: 'center',
          label: 'City',
          field: 'city',
        },
      ],
      pagination: {
        sortBy: 'desc',
        descending: false,
        page: 1,
        rowsPerPage: this.$q.screen.width == 1024 ? 14 : 7,
      },
    };
  },
  components: {
    // [Cities.name]: Cities,
    // [Regions.name]: Regions
  },
  computed: {
    pagesNumber() {
      return Math.ceil(
        this.cities.length / this.pagination.rowsPerPage,
      );
    },
    ...mapGetters({
      saveLoading: 'citiesRegions/form/saveLoading',
    }),
    disable() {
      if (this.cityEn && this.cityAr && !this.saveLoading) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapActions({
      doCreate: 'citiesRegions/form/doCreate',
    }),
    i18n(key, args) {
      return i18n(key, args);
    },
    doSubmit() {
      this.regions.forEach((region) => {
        this.doCreate(region);
      });
    },
    // onItemClick (id) {

    // },
    async addCity() {
      const city = {
        name: {
          en: this.cityEn,
          ar: this.cityAr,
        },
        regions: [],
      };
      await this.doCreate(city);
      this.cityDialog = false;
    },
  },
  mounted() {
    this.language = localStorage.getItem('language');
  },
  async created() {
    await firebase
      .firestore()
      .collection('city')
      .onSnapshot(
        { includeMetadataChanges: true },
        async () => {
          this.cities = [];
          const cities = await firebase
            .firestore()
            .collection('city')
            .get({ source: 'cache' });
          cities.forEach(async (doc) => {
            let city = doc.data();
            city['id'] = doc.id;
            // city['regions'] = []
            // let regions = await firebase.firestore().collection("city").doc(doc.id).collection("regions").get()
            // regions.forEach(async (region) =>{
            //   let regionInCity = region.data()
            //   city['regions'].push(regionInCity)
            //   regionInCity['city'] = city.name
            //   this.regions.push(regionInCity)

            // })

            this.cities.push(city);
          });

          this.loading = false;
        },
      );
  },
};
